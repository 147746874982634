import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import '~/styles/ProductCardPage.scss'

import SEO from '~/components/seo'
import ProductForm from '~/components/ProductForm'
import ProductCardSlider from '~/components/ProductCardSlider'

const ProductPage = ({ data }) => {
  const product = data.markdownRemark
  return (
    <>
      <SEO title={product.frontmatter.title} description={product.frontmatter.description} />
      <Helmet
        bodyAttributes={{
          class: "product-card-page"
        }}
      />
      <main className="product-card">
        <div className="product-card-images">
          <ProductCardSlider product={product} />
        </div>
        <div className="product-card-info">
          <div className="product-card-info-title">{product.frontmatter.title}</div>
          <div className="product-card-info-description">{product.frontmatter.description}</div>
          <ProductForm product={product} />
        </div>
      </main>
    </>
  )
}

export const query = graphql`
query ItemBySlug($slug: String!) {
  markdownRemark(fields: { slug: { eq: $slug } }) {
    html
    fields {
      slug
    }
    frontmatter {
      title
      description
      price
      id
      images {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            src
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      customField {
        name
        values {
          name
          priceChange
        }   
      }
    }
  }
}
`

export default ProductPage
