import React, { useState, useContext, useEffect, useCallback } from 'react'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import '~/styles/ProductForm.scss'

const ProductForm = ({ product }) => {

  const [selected, setSelected] = useState(product.frontmatter.customField.values[0].name)

  // create the string required by snipcart to allow price changes based on option chosen
  const createString = (values) => {
    return values.map(option => {
      const price = option.priceChange >= 0 ? `[+${option.priceChange}]` : `[${option.priceChange}]`
      return `${option.name}${price}`
    }).join('|')
  }

  // calculate price based on option selected for display on item page
  const updatePrice = (basePrice, values) => {
    const selectedOption = values.find(option => option.name === selected)
    return Intl.NumberFormat(undefined, {
      currency: 'USD',
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(basePrice + selectedOption.priceChange)

  }

  return (
    <>
      <div className="product-form-price">{updatePrice(product.frontmatter.price, product.frontmatter.customField.values)}</div>
      <div className="product-form-option">
        <label className="product-form-option-label" htmlFor={product.frontmatter.customField.name}>{product.frontmatter.customField.name}</label>
        <select
          className="product-form-option-select"
          onChange={(e) => setSelected(e.target.value)}
          id={product.frontmatter.customField.name}
        >
          {product.frontmatter.customField.values.map((option) => (<option key={option.name}>{option.name}</option>))}
        </select>
      </div>
      {/*<div className="product-form-quantity">
        <label className="product-form-quantity-label" htmlFor="quantity">Quantity</label>
        <input
          className="product-form-quantity-input"
          type="number"
          id="quantity"
          name="quantity"
          min="1"
          step="1"
          onChange={handleQuantityChange}
          value={quantity}
        />
  </div>*/}
      <button
        className="product-form-submit snipcart-add-item"
        data-item-id={product.frontmatter.id}
        data-item-price={product.frontmatter.price}
        data-item-name={product.frontmatter.title}
        data-item-description={product.frontmatter.description}
        data-item-image={product.frontmatter.images[1].childImageSharp.fluid.src}
        data-item-url={"https://pahhk.com" + product.fields.slug} //REPLACE WITH OWN URL
        data-item-custom1-name={product.frontmatter.customField ? product.frontmatter.customField.name : null}
        data-item-custom1-options={createString(product.frontmatter.customField.values)}
        data-item-custom1-value={selected}
      >
        Add to Cart
      </button>
    </>
  )
}

export default ProductForm
